<template>
  <div
    class="trip-view tw-flex tw-flex-col tw-items-center tw-py-10 tw-px-5 tw-mt-16"
  >
    <!-- "Are you sure" text -->
    <p
      class="tw-font-figtree tw-text-lg tw-font-semibold tw-leading-30 tw-text-dark-green tw-mb-6 tw-text-center"
    >
      Are you sure?
    </p>

    <!-- Detailed text -->
    <p
      class="tw-font-figtree tw-text-sm tw-font-normal tw-leading-22 tw-text-light-grey tw-px-4 tw-text-center tw-mb-8"
    >
      Canceling a trip will remove all trip details. When canceled, we will
      notify your crew and it will no longer be available. This cannot be
      undone.
    </p>

    <button
      @click="cancelTrip"
      style="width: 305px; height: 48px"
      class="tw-bg-chartreuse tw-flex tw-items-center tw-justify-center tw-font-figtree tw-font-semibold tw-text-charcoal tw-rounded-2xl tw-mx-auto tw-mt-36"
    >
      Cancel Trip
    </button>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" top right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false"> Close </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import feathersClient from "../feathers-client.js";
export default {
  name: "TripView",

  data() {
    return {
      snackbar: {
        show: false,
        message: "",
        color: ""
      }
    };
  },

  computed: {},

  methods: {
    async cancelTrip() {
      try {
        const tripService = feathersClient.service("trip");
        await tripService.patch(this.$route.params.id, { isCanceled: true });
        this.$router.push({ name: "CancelConfirmed" });
      } catch (error) {
        console.error("Error updating trip:", error);
        this.snackbar.message =
          "Unable to cancel trip. Please contact an admin.";
        this.snackbar.color = "error";
        this.snackbar.show = true;
      }
    }
  },

  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Cancel Trip",
      bgColor: null,
      fontColor: "#203848",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      showBackButton: true
    });
  }
};
</script>
